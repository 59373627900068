
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import AEFCHistory from "../components/aefc/AEFCHistory.vue";

import {getUserCountryCode} from '@/api/dashboard';

// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";


export default defineComponent({
  name: "bibo",
  components: {
    AEFCHistory
  },
  data(){
      return {
        ccode : 0
      }
  },
  methods : {
    async getUserCcode(){ 
      const response = await getUserCountryCode();
      this.ccode = response.data.ccode;
    }, 
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("aefcTab") || "0");
      setCurrentPageTitle("AEFC Compensation");

    });
    
  },
  mounted(){
      this.getUserCcode();
  },
});
